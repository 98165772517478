/*make sure to import all the other css files here for them to work in the project*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Inter-Medium";
    src: url("../fonts/Inter/Inter-Medium.ttf");
  }
  @font-face {
    font-family: "Inter-Regular";
    src: url("../fonts/Inter/Inter-Regular.ttf");
  }
  @font-face {
    font-family: "Inter-SemiBold";
    src: url("../fonts/Inter/Inter-SemiBold.ttf");
  }
  @font-face {
    font-family: "Inter-Bold";
    src: url("../fonts/Inter/Inter-Bold.ttf");
  }
  @font-face {
    font-family: "Inter-ExtraBold";
    src: url("../fonts/Inter/Inter-ExtraBold.ttf");
  }
  @font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins/Poppins-Medium.ttf");
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
  }
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
  }
  @font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins/Poppins-Bold.ttf");
  }
}

.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #000000 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }
  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }
  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 20px;
}

::-webkit-scrollbar-button {
  opacity: 0;
}
